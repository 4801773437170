<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('component_settings.service_precondition') }}</h4>
      </template>
      <template v-slot:body>
        <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
          <b-form  @submit.prevent="handleSubmit(searchData)" >
            <b-row>
              <b-col lg="6" sm="6" md="6">
                <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="organization"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                    {{ $t('org_pro.organization') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="search.org_id"
                      :options="organizationList"
                      id="org_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" sm="6" md="6">
                  <ValidationProvider name="Service Name" vid="service_id" rules="">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="service_id"
                      >
                      <template v-slot:label>
                      {{ $t('service_name.service_name') }}
                      </template>
                      <b-form-select
                        plain
                        v-model="search.service_id"
                        :options="serviceList"
                        id="org_id"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </ValidationProvider>
              </b-col>
              <b-col class="col-sm-2">
                <b-button type="submit" variant="primary">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('component_settings.service_precondition') }} {{ $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <router-link :to="`service-precondition-form`">
              <b-button class="mr-2" title="Add New" variant="primary" size="sm">{{ $t('globalTrans.add_new') }}</b-button>
            </router-link>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template slot="thead-top">
                      <tr>
                        <th class="text-center" width="50%" colspan="3">{{ $t('globalTrans.from') }} ({{ $t('dynamic_form.form') }})</th>
                        <th class="text-center" width="50%" colspan="4">{{ $t('globalTrans.to') }} ({{ $t('dynamic_form.form') }}) </th>
                      </tr>
                    </template>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(org_id_from)="data">
                      {{ getOrgName(data.item.org_id_from) }}
                    </template>
                    <template v-slot:cell(org_id_to)="data">
                      {{ getOrgName(data.item.org_id_to) }}
                    </template>
                    <template v-slot:cell(service_id_from)="data">
                      {{ getStoreData('licenseRegistration', 'serviceNamesList', data.item.service_id_from) }}
                    </template>
                    <template v-slot:cell(service_id_to)="data">
                      {{ getStoreData('licenseRegistration', 'serviceNamesList', data.item.service_id_to) }}
                    </template>
                    <template v-slot:cell(status)="data">
                      {{ data.item.status === 1 ? $t('globalTrans.active') : $t('globalTrans.inactive') }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <router-link :to="`service-precondition-form?id=` + data.item.id">
                        <b-button title="Edit" variant=" iq-bg-success mr-1 mb-1" size="sm"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      </router-link>
                      <b-button @click="changeStatus(data.item)" title="Permanent Delete" class="btn btn-danger btn-sm"> <i class="ri-close-line m-0"></i></b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { preconditionList, toggleStatus } from '../../api/routes'
import routeBaseMasterList from '@/mixins/route-base-list'
import common from '@/mixins/common'
import commonAuth from '../CommonAuth'

export default {
  mixins: [routeBaseMasterList, common, commonAuth],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      search: {
        org_id: 0,
        step_id: 0,
        service_id: 0
      },
      serviceList: []
    }
  },
  computed: {
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('globalTrans.organization'), class: 'text-left' },
        { label: this.$t('service_name.service_name'), class: 'text-left' },
        { label: this.$t('globalTrans.organization'), class: 'text-left' },
        { label: this.$t('service_name.service_name'), class: 'text-left' },
        { label: this.$t('globalTrans.status'), class: 'text-left' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]
      const keys = [
        { key: 'index' },
        { key: 'org_id_from' },
        { key: 'service_id_from' },
        { key: 'org_id_to' },
        { key: 'service_id_to' },
        { key: 'status' },
        { key: 'action' }
      ]
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.org_id': function (newVal) {
      this.serviceList = this.getServiceList(newVal)
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.search.org_id = this.userCheck()
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(licenseRegistrationServiceBaseUrl, preconditionList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.formateData(response.data.data))
          this.paginationData(response.data)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    formateData (data) {
      return data.map(item => {
        return Object.assign(item,
        // 1 form_service, 2 to service
        this.getServiceOrg(item.service_id_from, 1),
        this.getServiceOrg(item.service_id_to, 2)
        )
      })
    },
    getServiceOrg (serviceId, type = 0) {
      const service = this.$store.state.licenseRegistration.commonObj.serviceNamesList.find(item => item.value === parseInt(serviceId))
      if (type === 1) {
        return {
          org_id_from: service.org_id
        }
      } else {
        return {
          org_id_to: service.org_id
        }
      }
    },
    getOrgName (orgId) {
      const tmpOrg = this.organizationList.find(org => org.value === orgId)
      return this.$i18n.locale === 'en' ? tmpOrg !== undefined ? tmpOrg.text_en : '' : tmpOrg !== undefined ? tmpOrg.text_bn : ''
    },
    getServiceList (orgId) {
      return this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => item.status === 1 && item.org_id === orgId)
    },
    changeStatus (item) {
      window.vm.$swal({
        title: window.vm.$t('globalTrans.deleteConfirmMsg'),
        showCancelButton: true,
        confirmButtonText: window.vm.$t('globalTrans.yes'),
        cancelButtonText: window.vm.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.removeItem(item)
        }
      })
    },
    removeItem (item) {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.deleteData(licenseRegistrationServiceBaseUrl, `${toggleStatus}/${item.id}`).then(response => {
        if (response.success) {
          window.vm.$toast.success({
            title: 'Success',
            message: 'Data Updated Successfully',
            color: '#D6E09B'
          })
        } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
          })
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      })
    }
  }
}
</script>
